<template>
  <div>
    <h4 class="mb-5">User Lists</h4>
    <general-card class="">
      <v-card-title>
        <div class="d-flex align-center justify-space-between">
          <v-btn
            @click="$router.push('/tradedesk/create-user-lists')"
            depressed
            color="primary"
            >Create User List</v-btn
          >
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="user_lists"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        hide-default-footer
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.displayName="{ item }">
          <span class="font-weight-bold">{{ item.displayName }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="setStatusColor(item.status)"
            :text-color="setTextStatusColor(item.status)"
            class="font-weight-bold"
            small
          >
            <v-icon x-small left>mdi-circle</v-icon>
            <span class="text-capitalize">{{
              item.status.replaceAll("_", " ").toLowerCase()
            }}</span>
          </v-chip>
        </template>
      </v-data-table>
      <v-divider></v-divider>

      <v-card-actions class="d-flex align-center pa-4">
        <div class="caption mr-3">
          {{ `Page: ` + page + ` of ` + pageCount }}
        </div>
        <v-select
          v-model="itemsPerPage"
          :items="[10, 25, 50, 100, 250]"
          label="Items per page"
          outlined
          dense
          hide-details
          @input="itemsPerPage = parseInt($event, 10)"
          class="shrink"
          :menu-props="{ top: true, offsetY: true }"
        ></v-select>

        <v-spacer></v-spacer>

        <v-pagination v-model="page" :length="pageCount" class="custom"></v-pagination>
      </v-card-actions>
    </general-card>
  </div>
</template>

<script>
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    error: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    headers: [
      {
        text: "Display Name",
        value: "displayName",
      },
      { text: "Status", value: "status" },
    ],
  }),
  components: {
    GeneralCard,
  },
  computed: {
    user_lists() {
      return this.$store.state.dsp.user_lists;
    },
  },
  methods: {
    pagination() {
      this.itemsPerPage = parseInt(this.$event, 10);
    },
    setStatusColor(status) {
      if (status.trim() === "OPEN") return "green lighten-5";
      if (status.trim() === "CLOSED") return "orange lighten-5";
    },
    setTextStatusColor(status) {
      if (status.trim() === "OPEN") return "green ";
      if (status.trim() === "CLOSED") return "orange";
    },
    navigateToSingleUserList(userListId) {
      this.$router.push({
        name: "user-list",
        params: {
          id: userListId,
        },
      });
    },
  },
  async mounted() {
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getGoogleUserLists");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
      this.$store.commit("makingApiRequest", false);
    }
  },
};
</script>
